import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import ProductList from '../components/Posts/ProductList';
import { PageHeader } from '../components/PageHeader';
import { decodeEntities } from '../utils/helpers';

export default class Category extends React.Component {
  render() {
    const { data, pageContext, location } = this.props
    const { postsOnPage, categoryFilter, categories, site, wordpressWpSettings } = data
    const { edges: posts, totalCount } = postsOnPage
    const { title: siteTitle } = wordpressWpSettings
    const { name: category, slug } = pageContext
    const title = `${category} Category`

    return (
      <Layout className={`page-wrap`}>
        <SEO title={`${decodeEntities(category)} | ${decodeEntities(siteTitle)}`} />
        <PageHeader headerTitle={title} headerBackgroundImage="" location={location} />
        <ProductList
          posts={posts}
          title={title}
          pageContext={pageContext}
          categoryFilter={categoryFilter}
          siteMetadata={wordpressWpSettings}
          pathPrefix="products"
          pathSuffix={`category/${slug}/`}
        />
      </Layout>
    )
  }
}

Category.propTypes = {
  data: PropTypes.shape({
    allWordpressPost: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}

export const pageQuery = graphql`
  query ProductCategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    wordpressWpSettings {
      title
      blogSlug
      wordpressUrl
      siteUrl
    }
    categoryFilter: allWordpressWpProducts {
      nodes {
        categories {
          wordpress_id
          id
          name
          slug
          id
        }
      }
    }
    postsOnPage: allWordpressWpProducts(
      filter: {
        categories: {elemMatch: {slug: {eq: $slug}}},
        slug: { ne: "dummy-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          ...ProductListFields
        }
      }
    }
    categories: allWordpressCategory(filter: { count: { gt: 0 } }) {
      edges {
        node {
          name
          slug
          count
        }
      }
    }
  }
`
